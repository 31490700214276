import { format, isSameDay } from 'date-fns'

export function parseUtcDate(date: string) {
  return new Date(date + '+00:00')
}

export function isSameHalfOfDay(date1: Date, date2: Date) {
  return (
    (date1.getHours() < 12 && date2.getHours() < 12) ||
    (date1.getHours() >= 12 && date2.getHours() >= 12)
  )
}

export function formatSigningTime(dateFrom: Date, dateTo: Date) {
  if (isSameDay(dateFrom, dateTo)) {
    return (
      format(dateFrom, `h:mm${isSameHalfOfDay(dateFrom, dateTo) ? '' : 'a'}-`) +
      format(dateTo, 'h:mma EEEE d LLLL yyyy')
    )
  } else {
    return (
      format(dateFrom, 'h:mma EEEE d LLLL yyyy') +
      ' to ' +
      format(dateTo, 'h:mma EEEE d LLLL yyyy')
    )
  }
}
